@import url('https://fonts.googleapis.com/css2?family=Karla:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

* {
    font-family: "Avenir Next" !important;
}
.App {
    height: 100vh;
    color: white;
}

.logo_img {
    width: 350px;
    height: auto;
    margin-bottom: 20px;
   
}

.header {
    height: 700px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-position: center;
}



.header_overlay {
    background-color: rgba(50, 50, 50, 0.544);
    position: absolute;
    top: 0;
    width: 100%;
    height: 700px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.footer {
    padding-bottom: 30px;
    padding-top: 30px;
    background-color: black;
    color: white;
    font-size: 14px;
    text-align: left;
    padding-left: 20px;
}

.footer p {
    text-align: left;
}

.content {

    padding: 100px 0;
}

.button_div {

    margin-top: 50px;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
        width: 7px;
        margin-left: 10px;
    }
}

.button_div a {
   background-color: #21674D;
    color: white;
    padding: 15px 50px;
     border-radius: 3px;
     margin-top: 30px;
     font-size: 20px;
     display: flex;
     justify-content: center;
     align-items: center;
     font-weight: 500;
 
}

@media only screen and (max-width: 800px){
    .content {
        padding: 50px 0;
    }
}

a {

    text-decoration: none;
}

.claim {
    font-size: 22px;
}

.kunden {
    padding: 25px 0;
}

.kunden h3 {
    color:rgb(65, 65, 65);
    font-size: 32px;
    margin: 30px 0;
}

.kunden img {
    width: 150px;
    height: auto;
}


.kunden_wrapper {
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
    padding: 0 50px;

    img {
        width: 120px;
    }
}

.kunden_img_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}


.header_overlay {
    display: grid;
    grid-template-columns: 50% 50%;
}

.header_content {
    position: relative;
    top: -100px;
}


.header p {
    font-size: 24px;
}



@media only screen and (min-width: 1500px){
    
    .header {
        height: 900px;
    }

    .header_overlay {
        height: 900px;
    }
}


.footer_inner_wrapper {
    display: grid;
    grid-template-columns: 50% 50%;
    width: 100%;
    p {
        line-height: 1;
    }
}



@media only screen and (max-width: 750px){
    .kunden_wrapper {
        grid-template-columns: 50% 50%;
        grid-row-gap: 40px;
        padding: 20px;
    }
    .kunden h3 {
        color:rgb(65, 65, 65);
        font-size: 32px;
        margin: 20px 0;
    }

    .header_overlay {
        display: grid;
        grid-template-columns: 100%;
    }

    .footer_inner_wrapper {
        display: grid;
        grid-template-columns: 50% 50%;
        width: 100%;
  
    }
    

}

.footer_col_left {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    p {
        margin: 0;
        margin-bottom: 10px;
        
    }
}

.footer_col_right {
    padding-left: 30px;
}

@media only screen and (max-width: 1800px){
    .kunden_wrapper {
        padding: 0 200px;
    }
}

@media only screen and (max-width: 1100px){
    .kunden_wrapper {
        padding: 0 100px;
    }
}

@media only screen and (max-width: 700px){
    .kunden_wrapper {
        padding: 0 25px;
      
   
    }

    .footer_col_left p {
        width: 100%;
    }
}